<template>
    <div class="how-add">
        <h1>How to add an advertising account?</h1>
        <ol>
            <li>In the "Accounts" section, select the desired advertising platform</li>
            <li>Click on the "Add account" button</li>
            <li>Fill out and submit the form</li>
            <li>If the account is not added, write to the support team</li>
        </ol>
        <p>
            Your request is being pre-moderated.
            - If the team has any clarifying questions, you will be contacted via the available communication channels
            (email/messenger/phone)
        </p>
        <p>
            <b><i>Important: an advertising account cannot be added to multiple business accounts (legal
                    entities/individuals)</i></b>
        </p>
        <h2>Advertising account statuses</h2>
        <ul class="dash-list">
            <li>
                In case of successful creation and addition, the status will change to "created" (i.e. successfully
                created), and after some time (up to an hour) the status will become "active" (i.e. ready to work, since
                data on balance, campaigns and statistics have been received). An advertising account with the "active"
                status can be replenished, their campaign limits can be changed (if the campaign budget strategy is used,
                not the daily budget).
            </li>
            <li>
                In case of unsuccessful addition, the status of the advertising account will be "declined", which indicates
                that it is impossible to use this advertising account, in this case contact the support service.
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "HowAdd",
    data() {
        return {
            locale: process.env.VUE_APP_LOCALE,
        }
    }
}
</script>
<style lang="scss" scoped>
.how-add {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    h1{
        line-height: normal;
    }
    ol,
    ul {
        list-style-position: inside;
    }
}</style>